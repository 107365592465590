<template>
  <v-app>
    <!-- 1°first ROW -->
    <div class="row">
      <div class="col-md-12">
        <!-- 1°first Card -->
        <v-card outlined tile elevation="1">
          <v-card-title>
            Productos
            <v-spacer></v-spacer>
            <v-tooltip bottom>
              <template v-slot:activator="{ on}">
                <v-btn v-on="on" class @click="reinitialize" text icon color="blue">
                  <v-icon>mdi-refresh</v-icon>
                </v-btn>
              </template>
              <span>Actualizar Tabla</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn v-on="on" class @click="exportExcel" text icon color="green">
                  <v-icon>fas fa-file-excel</v-icon>
                </v-btn>
              </template>
              <span>Exportar Excel</span>
            </v-tooltip>
          </v-card-title>
           <div v-if="loadTransition"  class="alert bluesky-line m-0 mb-0" ></div>
          <v-card-text>
            <v-data-table
              :headers="headers"
              :items="productList"
              :page.sync="page"
              :items-per-page="itemsPerPage"
              :single-select="true"
              show-select
              v-model="selected"
              :search="search"
              :sort-by="'id'"
              :sort-desc="true"
              class="elevation-1 datatable"
            >
              <template v-slot:top>
                <v-card class="mt-1">
                  <v-spacer></v-spacer>
                  <v-toolbar flat color="white">
                  <div class="kt-input-icon kt-input-icon--left">
                    <input
                      v-model="search"
                      append-icon="search"
                      label="Búsqueda"
                      type="text"
                      class="form-control form-control-md col-md-8"
                      placeholder="Buscar..."
                    />
                    <span class="kt-input-icon__icon kt-input-icon__icon--left">
                      <span>
                        <i class="la la-search"></i>
                      </span>
                    </span>
                  </div>
                   <!-- <v-text-field
                      class="text-xs-center"
                      v-model="search"
                      append-icon="search"
                      label="Búsqueda"
                    ></v-text-field>-->
                    
                    <v-spacer></v-spacer>
                    <v-divider class="mx-4" inset vertical></v-divider>
                    <v-btn class="mb-2 mr-2 btn-starkoms-primary" dark href="/#/inventario/product-bulk-load" >Carga masiva</v-btn>
                    <v-divider class="mx-4" inset vertical></v-divider>
                    <v-dialog v-model="dialog" max-width="600px">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn dark class="mb-2 btn-starkoms-primary" v-bind="attrs" v-on="on">
                          <i class="fa fa-plus"></i> producto
                        </v-btn>
                      </template>
                      <!-- 2°second Card -->
                      <v-card>
                        <v-card-title>
                          <span class="headline">{{ formTitle }}</span>
                        </v-card-title>

                        <v-card-text>
                          <v-container>
                            <v-form ref="form" v-model="valid" lazy-validation>
                              <!-- 2°second ROW -->
                              <v-row>
                                <v-col cols="12" sm="6" md="2" v-if="editedItem.id">
                                  <v-text-field
                                    v-model="editedItem.id"
                                    name="id"
                                    readonly="readonly"
                                    label="ID"
                                    prepend-icon="vpn_key"
                                    filled
                                  ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6" md="6">
                                  <v-text-field
                                    v-model="editedItem.name"
                                    label="producto"
                                    :rules="[v => !!v || 'El nombre del Producto es requerido!']"
                                    prepend-icon="add_business"
                                  ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6" md="6">
                                  <v-select
                                    v-model="editedItem.category_id"
                                    :items="categories"
                                    :rules="[v => !!v || 'Seleccionar una Categoría del Producto es requerido!']"
                                    item-text="name"
                                    item-value="id"
                                    label="Categorías"
                                  ></v-select>
                                </v-col>
                                <v-col cols="12" sm="6" md="6">
                                  <v-text-field
                                    v-model="editedItem.price"
                                    label="Precio"
                                    type="number"
                                    :rules="[v => !!v || 'El $ Precio del Producto es requerido!']"
                                    prefix="$"
                                  ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6" md="6">
                                  <v-text-field
                                    v-model="editedItem.cost"
                                    :rules="[v => !!v || 'El $ Costo del Producto es requerido!']"
                                    type="number"
                                    label="Costo"
                                    prefix="$"
                                  ></v-text-field>
                                </v-col>

                                <v-col cols="12" sm="6" md="6">
                                  <v-text-field
                                    v-model="editedItem.sku"
                                    label="SKU"
                                    :rules="[v => !!v || 'El SKU del Producto es requerido!']"
                                  ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6" md="6">
                                  <v-text-field v-model="editedItem.ean" label="EAN"></v-text-field>
                                </v-col>
                              </v-row>
                            </v-form>
                          </v-container>
                        </v-card-text>

                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn color="#FF6347" text @click="close">Cancelar</v-btn>
                          <v-btn color="#00FF00" text @click="save">Guardar</v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                  </v-toolbar>
                </v-card>
              </template>
              <template v-slot:item.price="{ item }">
                <v-chip :color="getColorPrice(item.price)" dark>{{ item.price }}</v-chip>
              </template>
              <template v-slot:item.actions="{ item }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on}">
                    <router-link :to="`/inventario/productos/${item.id}`">
                      <v-icon v-on="on" small color="#93a2dd" class="mr-2">mdi-eye</v-icon>
                    </router-link>
                  </template>
                  <span>Ver Producto {{item.id}}</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{on}">
                    <v-icon small v-on="on" color="#93a2dd" class="mr-2" @click="editItem(item)">mdi-pencil</v-icon>
                  </template>
                  <span>Editar Producto {{item.id}}</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on}">
                    <v-icon v-on="on" color="#93a2dd" small @click="deleteItem(item)">mdi-delete</v-icon>
                  </template>
                  <span>Eliminar Producto {{item.id}}</span>
                </v-tooltip>
              </template>
              <template v-slot:no-data>
                <v-btn color="#87CEFA" :loading="loading" @click="reinitialize()">Cargando ..</v-btn>
              </template>
            </v-data-table>
            <div class="text-center pt-2">
              <v-pagination
                v-model="page"
                :total-visible="10"
                :length="pageCount"
                color="#BA68C"
                prev-icon="mdi-menu-left"
                next-icon="mdi-menu-right"
              ></v-pagination>
            </div>
          </v-card-text>
        </v-card>
      </div>
    </div>
  </v-app>
</template>

<script>
import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";

export default {
  props: {
    store_id: {
      type: Number,
      default: 1,
    },
  },
  data: () => ({
    page: 1,
    pageCount: 0,
    itemsPerPage: 10,
    dialog: false,
    valid: true,
    loading: false,
    url: "",
    boolean: false,
    loadTransition: false,
    search: "",
    selected: [],
    productList: [],
    windowSize: {
      x: 0,
      y: 0,
    },
    dateMsg:
      new Date().toISOString().substr(0, 10) +
      " " +
      new Date().toISOString().substr(11, 5),
    productsByStore: [],
    categories: [],
    editedIndex: -1,
    editedItem: {
      id: 0,
      name: "",
      sku: 0,
      ean: "",
      price: 0,
      cost: 0,
      category_id: 0,
      store_id: 0,
      created_at: "",
      updated_at: "",
      categories: {
        id: 1,
        name: "",
        parent_category: null,
        created_at: null,
        updated_at: null,
      },
    },
    defaultItem: {
      id: 0,
      name: "",
      sku: 0,
      ean: "",
      price: 0,
      cost: 0,
      category_id: 0,
      store_id: 0,
      created_at: "",
      updated_at: "",
      categories: {
        id: 0,
        name: "",
        parent_category: null,
        created_at: null,
        updated_at: null,
      },
    },
  }),
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Productos", route: "/inventario/productos" },
    ]);
    this.loading = true;
    this.fetchProducts();
    this.fetchCategories();
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Nuevo Producto" : "Editar Producto";
    },
    headers() {
      return [
        {
          text: "ID",
          align: "start",
          sortable: false,
          value: "id",
        },
        {
          text: "Nombre",
          value: "name",
        },
        {
          text: "Sku",
          value: "sku",
        },
        { text: "Categorias", value: "category.name" },
        { text: "Precio", value: "price" },
        { text: "Acciones", value: "actions", sortable: false },
      ];
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
  },

  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      if (localStorage.getItem("deleteProducto") !== null) {
        this.deletedProductMessage();
      }
    },
    editItem(item) {
      //console.log(item);
      this.editedIndex = this.productList.indexOf(item);
      //console.log(this.productList);
      this.editedItem = Object.assign({}, item);

      //console.log(Object.assign({}, this.editedItem));

      //  console.log(product);
      this.dialog = true;
    },
    deleteItem(item) {
      var vm = this;
      let deleteProducto = Object.assign({}, item);

      const confirmacion = confirm(
        `Esta seguro de eliminar el Producto: ${deleteProducto.name} || ID: ${deleteProducto.id}?`
      );
      if (confirmacion) {
        vm.axios({
          url: "inventory/products/" + deleteProducto.id,
          method: "DELETE",
        })
          .then((response) => {
            vm.fetchProducts();

            vm.$bvToast.toast(
              `El Producto: ${deleteProducto.name} ha sido eliminado, el ${vm.dateMsg} exitosamente!`,
              {
                title: `Información`,
                variant: "success",
                solid: true,
                toaster: "b-toaster-bottom-center",
              }
            );
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    save() {
      var vm = this;

      //console.log(this.$refs.form);

      if (this.editedIndex > -1) {
        //Object.assign(this.productList[this.editedIndex]);

        // EDIT PRODUCT
        let editedProduct = Object.assign({}, this.editedItem);
        //console.log(editedProduct.name); 

       if (!vm.editedItem.name.length || vm.editedItem.name == null) {
          vm.$bvToast.toast("El nombre del producto no debe ir vacío", {
            title: `Formulario Invalido`,
            variant: "danger",
            solid: true,
            toaster: "b-toaster-bottom-center",
          });
          vm.$refs.form.$el[1].focus();
          return;
        }

        if (
          vm.editedItem.category_id === 0 ||
          isNaN(vm.editedItem.category_id)
        ) {
          vm.$bvToast.toast("Seleccione una categoría del producto porfavor", {
            title: `Formulario Invalido`,
            variant: "danger",
            solid: true,
            toaster: "b-toaster-bottom-center",
          });
          vm.$refs.form.$el[2].focus();
          return;
        }

        if (vm.editedItem.price === 0 || isNaN(vm.editedItem.price)) {
          vm.$bvToast.toast("El precio del producto no debe ir vacío", {
            title: `Formulario Invalido`,
            variant: "danger",
            solid: true,
            toaster: "b-toaster-bottom-center",
          });
          vm.$refs.form.$el[4].focus();
          return;
        }

        if (vm.editedItem.cost === 0 || isNaN(vm.editedItem.cost)) {
          vm.$bvToast.toast("El costo del producto no debe ir vacío", {
            title: `Formulario Invalido`,
            variant: "danger",
            solid: true,
            toaster: "b-toaster-bottom-center",
          });
          vm.$refs.form.$el[5].focus();
          return;
        }

        if (!vm.editedItem.sku.length || vm.editedItem.sku == null) {
          vm.$bvToast.toast("El SKU del producto no debe ir vacío", {
            title: `Formulario Invalido`,
            variant: "danger",
            solid: true,
            toaster: "b-toaster-bottom-center",
          });
          vm.$refs.form.$el[6].focus();
          return;
        }

        if (this.$refs.form.validate()) {
          vm.loadTransition = true;
          this.axios({
            url: "inventory/products/" + vm.editedItem.id,
            method: "PUT",
            data: {
              sku: vm.editedItem.sku,
              name: vm.editedItem.name,
              cost: vm.editedItem.cost,
              price: vm.editedItem.price,
              ean: vm.editedItem.ean,
              category_id: vm.editedItem.category_id,
              store_id: vm.store_id,
            },
          })
            .then((response) => {
              vm.fetchProducts();

              vm.$bvToast.toast(
                `El producto: ${editedProduct.name}, ha sido Actualizado el ${vm.dateMsg} exitosamente!`,
                {
                  title: `Información`,
                  variant: "success",
                  solid: true,
                  toaster: "b-toaster-bottom-center",
                }
              );

              vm.loadTransition = false;
              this.$refs.form.reset();
            })
            .catch((error) => {
              console.log(error);
            });
        }
      } else {
        //this.productsByStore.data.push(product);

        // CREATE PRODUCT
        let createProduct = Object.assign({}, this.editedItem);

        if (!vm.editedItem.name.length || vm.editedItem.name == null) {
          vm.$bvToast.toast("El nombre del producto no debe ir vacío", {
            title: `Formulario Invalido`,
            variant: "danger",
            solid: true,
            toaster: "b-toaster-bottom-center",
          });
          vm.$refs.form.$el[0].focus();
          return;
        }

        if (
          vm.editedItem.category_id === 0 ||
          isNaN(vm.editedItem.category_id)
        ) {
          vm.$bvToast.toast("Seleccione una categoría del producto porfavor", {
            title: `Formulario Invalido`,
            variant: "danger",
            solid: true,
            toaster: "b-toaster-bottom-center",
          });
          vm.$refs.form.$el[1].focus();
          return;
        }

        if (vm.editedItem.price === 0 || isNaN(vm.editedItem.price)) {
          vm.$bvToast.toast("El precio del producto no debe ir vacío", {
            title: `Formulario Invalido`,
            variant: "danger",
            solid: true,
            toaster: "b-toaster-bottom-center",
          });
          vm.$refs.form.$el[3].focus();
          return;
        }

        if (vm.editedItem.cost === 0 || isNaN(vm.editedItem.cost)) {
          vm.$bvToast.toast("El costo del producto no debe ir vacío", {
            title: `Formulario Invalido`,
            variant: "danger",
            solid: true,
            toaster: "b-toaster-bottom-center",
          });
          vm.$refs.form.$el[4].focus();
          return;
        }

        if (!vm.editedItem.sku.length || vm.editedItem.sku == null) {
          vm.$bvToast.toast("El SKU del producto no debe ir vacío", {
            title: `Formulario Invalido`,
            variant: "danger",
            solid: true,
            toaster: "b-toaster-bottom-center",
          });
          vm.$refs.form.$el[5].focus();
          return;
        }

        if (this.$refs.form.validate()) {

          vm.loadTransition = true;

          this.axios({
            url: "inventory/products",
            method: "POST",
            data: {
              sku: vm.editedItem.sku,
              name: vm.editedItem.name,
              cost: vm.editedItem.cost,
              price: vm.editedItem.price,
              ean: vm.editedItem.ean,
              category_id: vm.editedItem.category_id,
              store_id: vm.store_id,
            },
          })
            .then((response) => {
              vm.fetchProducts();
              vm.$bvToast.toast(
                `El producto: ${createProduct.name}, ha sido guardado correctamente!`,
                {
                  title: `Información`,
                  variant: "success",
                  solid: true,
                  toaster: "b-toaster-bottom-center",
                }
              );
              vm.loadTransition = false;
              this.$refs.form.reset();
            })
            .catch((error) => {
              console.log(error);
            });
        }
      }
      this.close();
    },
    fetchProducts() {
      var vm = this;
      this.axios({
        url: "inventory/products/by_store",
        method: "GET",
      })
        .then((response) => {
          console.log(response.data.data);
          this.loading = false;
          vm.pageCount = Math.ceil(response.data.data.length / 10);
          vm.productList = response.data.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    fetchCategories() {
      var vm = this;
      this.axios({
        url: "inventory/categories",
        method: "GET",
      })
        .then((response) => {
          //console.log(response.data.data);
          vm.categories = response.data.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    onResize() {
      this.windowSize = { x: window.innerWidth, y: window.innerHeight };
    },
    getColorPrice(price) {
      if (price >= 15000) return "#32CD32";
      else if (price >= 10000) return "#D2691E";
      else return "#B22222";
    },
    deletedProductMessage() {
      let deletedProduct = JSON.parse(localStorage.getItem("deleteProducto"));

      this.$bvToast.toast(
        `El Producto: ${deletedProduct.name}, ha sido eliminado, el ${this.dateMsg} exitosamente!`,
        {
          title: `Información`,
          variant: "success",
          solid: true,
          toaster: "b-toaster-bottom-center",
        }
      );
      localStorage.removeItem("deleteProducto");
    },
    reinitialize() {
      this.productList = [];
      this.loading = true;
      this.fetchProducts();
    },
    exportExcel() {
      var vm = this;
      let headers = vm.headers.map((e) => e.text);
      let datos = vm.productList;
      this.axios({
        url: "excel/from_array",
        method: "POST",
        data: {
          data: datos,
          cabeceras: headers,
        },
        responseType: "blob",
      })
        .then((response) => {
          const newBlob = new Blob([response.data], {
            type:
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;",
          });
          const data = window.URL.createObjectURL(newBlob);
          window.open(data, "_blank");
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>